@import url('../../../styles/component.css');

* {
    /* reset */
    margin: 0px;
}

:host {

    max-width: 700px;
    color: var(--color--light-text);

}

section {
    display: flex;
    flex-direction: column;
}


h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 600;
    letter-spacing: -0.06rem;
}

h1>handwritten-text {

    letter-spacing: 0;
}

h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 100;
    margin-top: .5rem;
}
